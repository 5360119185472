import axios from "axios";
import authHeader from "./auth-header";

const list = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/fusions', method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const save = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/fusions/', method: 'post', headers: authHeader(), data: data
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const update = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/fusions/', method: 'put', headers: authHeader(), data: data
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const del = async (id) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/fusions/'+id, method: 'delete', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const setFusionState = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/fusions/state', method: 'post', headers: authHeader(), data: data
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const fusion = {
    list,
    save,
    update,
    del,
    setFusionState
};

export default fusion;