import axios from "axios";
import authHeader from "./auth-header";

// list -------------------------------------------------------------
const list = async (date) => {
    let queryParam = '?date=' + date;
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/venues' + queryParam, method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const getActivatedVenue = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/venues/activated', method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const deactivate = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/venues/deactivate', method: 'post', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const activate = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/venues/activate', method: 'post', headers: authHeader(), data: data
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const listAll = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/venues', method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const save = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/venues', method: 'post', headers: authHeader(), data: data
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

// default export ---------------------------------------------------
const venue = {
    activate,
    deactivate,
    list,
    listAll,
    getActivatedVenue,
    save
};

export default venue;