import axios from "axios";
import authHeader from "./auth-header";

const getTrackersState = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/trackers/state', method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const setTrackerState = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/trackers/state', method: 'post', headers: authHeader(), data: data
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const setAllTrackersState = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/trackers/all_state', method: 'post', headers: authHeader(), data: data
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const fusion = {
    getTrackersState,
    setTrackerState,
    setAllTrackersState
};

export default fusion;