import React, {useEffect, useState} from "react";
import {Button, Form, Input} from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import {useTranslation} from "react-i18next";
// INTERNAL
import Loader from "../components/loader"
import fusion from "../services/fusion.service";

const layout = {
    labelCol: {span: 8}, wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

export default function TrackerFusionForm() {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const {id} = useParams();
    const [form] = Form.useForm();
    const [mode, setMode] = useState();
    const history = useHistory();

    const getFusion = (fusionId) => {
        fusion.list()
            .then((r) => {
                if (r && r.data) {
                    const fusion = r.data.find(fusion => fusion.fusionId === fusionId);
                    if (fusion) {
                        form.setFieldsValue({
                            id: fusion.id,
                            fusionId: fusion.fusionId,
                            trackerId1: fusion.trackers[0] ? fusion.trackers[0] : '',
                            trackerId2: fusion.trackers[1] ? fusion.trackers[1] : '',
                            trackerId3: fusion.trackers[2] ? fusion.trackers[2] : '',
                            trackerId4: fusion.trackers[3] ? fusion.trackers[3] : '',
                            trackerId5: fusion.trackers[4] ? fusion.trackers[4] : '',
                        });
                    }
                }
            });
    }

    const convertFormDataToFusion = (formData) => {
        return {
            id: formData.id,
            fusionId: formData.fusionId,
            trackers: [formData.trackerId1, formData.trackerId2, formData.trackerId3, formData.trackerId4, formData.trackerId5]
                .filter(Number)
        }
    }

    const createFusion = (formData) => {
        fusion.save(convertFormDataToFusion(formData)).then(() => {});
    }

    const editFusion = (formData) => {
        fusion.update(convertFormDataToFusion(formData)).then(() => {});
    }

    // handler --------------------------------------------------------
    const save = (formData) => {
        if (mode === "add") {
            createFusion(formData);
        } else {
            editFusion(formData);
        }
        history.push("/fusions");
    }

    // useEffect ------------------------------------------------------

    useEffect(() => {
        if (id) {
            setMode('mod');
            getFusion(Number(id));
        } else {
            setMode('add');
        }
        setLoading(false);
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // render ---------------------------------------------------------

    return (
        <div className="private_page_wrapper">
            <div className="header">
                <div className="sub_header_title">
                    <span className="title">
                        {(mode === 'add') ? t('TrackerFusion.addFusionTitle') : t('TrackerFusion.editFusionTitle') + id}
                    </span>
                </div>
            </div>
            {loading ? <Loader/> : <Form
                {...layout}
                form={form}
                className="common_form"
                onFinish={save}>
                <Form.Item name="id" label={t('TrackerFusion.id')} hidden={true}>
                    <Input disabled={true}/>
                </Form.Item>

                <Form.Item name="fusionId" label={t('TrackerFusion.fusionId')} >
                    <Input disabled={mode === 'mod'} type="text" />
                </Form.Item>

                <Form.Item name="trackerId1" label={t('TrackerFusion.trackerId1')}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item name="trackerId2" label={t('TrackerFusion.trackerId2')}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item name="trackerId3" label={t('TrackerFusion.trackerId3')}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item name="trackerId4" label={t('TrackerFusion.trackerId4')}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item name="trackerId5" label={t('TrackerFusion.trackerId5')}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item {...tailLayout} className="form_toolbar">
                    <Button type="primary" htmlType="submit" onClick={() => history.push("/fusions")}>
                        {t('common.back')}
                    </Button>
                    <Button type="primary" htmlType="submit">
                        {t('common.save')}
                    </Button>
                </Form.Item>
            </Form>}
        </div>
    )
}