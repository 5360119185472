import axios from "axios";
import authHeader from "./auth-header";

// list -------------------------------------------------------------
const list = async () => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/tracks', method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

// save -------------------------------------------------------------
const save = async (data) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/tracks/', method: 'post', headers: authHeader(), data: data
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

// del --------------------------------------------------------------
const del = async (uuid) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/tracks/' + uuid, method: 'delete', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

// del --------------------------------------------------------------
const getContent = async (uuid) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/tracks/content/' + uuid, method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const get = async (uuid) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + '/api/v1/tracks/' + uuid, method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

const getByCodeAndVenue = async (code, venueCode) => {
    return await axios({
        url: process.env.REACT_APP_SERVER_URL + `/api/v1/venues/${venueCode}/tracks/${code}`,
        method: 'get', headers: authHeader()
    }).catch(function (error) {
        console.log(error.response.data);
    })
}

// default export ---------------------------------------------------
const track = {
    list,
    save,
    del,
    get,
    getByCodeAndVenue,
    getContent
};

export default track;