import React, {useEffect, useState} from "react";
import {Button, Form, Input, message} from 'antd';
import {useHistory} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import venue from "../services/venue.service";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

export default function RaceTrackForm() {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const history = useHistory();
    const [venueList, setVenueList] = useState([]);

    const save = async (values) => {
        const formData = new FormData();
        formData.append("code", values.Code);
        formData.append("label", values.Label);
        formData.append("address", values.Address);
        formData.append("city", values.City);
        venue.save(formData).then(res => {
            if (res.status !== 200 && res.status !== 204) {
                message.error(t('Track.Error'));
            } else {
                message.success(t('Track.Saved'));
            }
            history.push('/track/');
        })
    }

    // handler --------------------------------------------------------

    // useEffect ------------------------------------------------------
    useEffect(() => {
        venue.listAll().then(res => setVenueList(res.data.map(venue => venue.code).map(code => code.toUpperCase())));
    }, [])

    // render ---------------------------------------------------------
    return (
        <div className="private_page_wrapper">
            <div className="header">
                <div className="sub_header_title">
                    <span className="title">
                        {t('Track.NewSite')}
                    </span>
                </div>
            </div>
            <Form
                {...layout}
                form={form}
                className="common_form"
                onFinish={save}>

                <Form.Item name="Code"
                           label={t('Track.Code')}
                           hasFeedback
                           rules={[
                                   {required: true},
                                   () => ({
                                       validator(rule, value) {
                                           if (value && venueList.includes(value.toUpperCase())) {
                                               return Promise.reject(t('Track.AlreadyExistingSiteCode'));
                                           } else {
                                                return Promise.resolve();
                                           }
                                       },
                                   })]}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item name="Label" label={t('Track.Label')} rules={[{required: true}]}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item name="City" label={t('Track.City')} rules={[{required: false}]}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item name="Address" label={t('Track.Address')} rules={[{required: false}]}>
                    <Input type="text"/>
                </Form.Item>

                <Form.Item {...tailLayout} className="form_toolbar">
                    <Button type="primary" htmlType="submit">
                        {t('Track.Save')}
                    </Button>

                    <Button type="primary" htmlType="submit" onClick={() => history.push("/track")}>
                        {t('Track.Cancel')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}