import React, {useEffect, useState} from "react";
import {Button, Form, Input, message} from 'antd';
import {useHistory} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import Papa from 'papaparse';
// INTERNAL
import fusion from "../services/fusion.service";

const layout = {
    labelCol: {span: 8}, wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};
const FUSION_ID_INDEX = 0;
const TRACKER_ID_1_INDEX = 1;
const TRACKER_ID_2_INDEX = 2;
const TRACKER_ID_3_INDEX = 3;
const TRACKER_ID_4_INDEX = 4;
const TRACKER_ID_5_INDEX = 5;

export default function TrackerFusionImport() {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const history = useHistory();
    const [file, setFile] = useState();
    const [availableFusions, setAvailableFusions] = useState([]);

    const getFusionList = () => {
        fusion.list()
            .then((r) => {
                if (r && r.data) {
                    setAvailableFusions(r.data.map(fusion => {
                        return {
                            id: fusion.id,
                            fusionId: fusion.fusionId
                        }
                    }));
                }
            });
    }

    const convertCsvLineToFusionJson = (fusionData) => {
        return {
            fusionId: fusionData[FUSION_ID_INDEX],
            trackers: [fusionData[TRACKER_ID_1_INDEX],
                       fusionData[TRACKER_ID_2_INDEX],
                       fusionData[TRACKER_ID_3_INDEX],
                       fusionData[TRACKER_ID_4_INDEX],
                       fusionData[TRACKER_ID_5_INDEX]].filter(Number)
        }
    }

    const handleCsv = () => {
        if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {
            console.log('The File APIs are not fully supported in this browser.');
            return false;
        }
        if (!file) {
            console.log("This browser doesn't seem to support the `files` property of file inputs.");
            console.log("No file selected.");
            return false;
        } else {
            Papa.parse(file, {
                keepEmptyRows:false,
                skipEmptyLines: true,
                complete: function(results) {
                    if (results.errors.length > 0) {
                        message.error(t('TrackerFusion.importFailureUnknownError'));
                    } else {
                        // first check all values are integer, if not, throw an error
                        for (let i = 1; i < results.data.length; i++) {
                            // tolerate last empty line
                            if (results.data[i-1].length === 0 ) {

                            }
                            if (results.data[i].length !== 6) { // 1 Id + 5 possible trackers Id = 6
                                message.error(t('TrackerFusion.importFailureTooManyElements'));
                                return;
                            }
                            for (let j = 0; j < 6 ; j++) {
                                if (results.data[i][j].length > 0) {
                                    let value = parseInt(results.data[i][j]);
                                    if (isNaN(value)) {
                                        message.error(t('TrackerFusion.importFailureNotANumber'));
                                        return;
                                    }
                                }
                            }
                        }
                        for (let i = 1; i<results.data.length; i++) {
                            let existingFusion =
                                availableFusions.find(fusion => fusion.fusionId === Number(results.data[i][FUSION_ID_INDEX]));
                            if (existingFusion) {
                                fusion.del(existingFusion.id);
                            }
                            fusion.save(convertCsvLineToFusionJson(results.data[i]));
                        }
                        setTimeout(() => {
                            message.success(t('TrackerFusion.importSuccessMessage'));
                            history.push("/fusions");
                        }, 1000)
                    }
                }
            });
        }
    }

    function handleUpload(event) {
        if (event.target.files[0]) {
            setFile(event.target.files[0]);
        }
    }

    // handler --------------------------------------------------------
    const save = () => {
        handleCsv();
    }

    useEffect(() => {
        getFusionList();
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // render ---------------------------------------------------------
    return (
        <div className="private_page_wrapper">
            <div className="header">
                <div className="sub_header_title">
                    <span className="title">
                        {t('TrackerFusion.importFusionTitle')}
                    </span>
                </div>
            </div>
            <Form
                {...layout}
                form={form}
                className="common_form"
                onFinish={save}>
                <Form.Item name="file" label={t('TrackerFusion.chooseFileToImport')} rules={[{required: true}]}>
                    <Input type="file" onChange={handleUpload}/>
                </Form.Item>

                <Form.Item {...tailLayout} className="form_toolbar">
                    <Button type="primary" htmlType="submit">
                        {t('common.back')}
                    </Button>
                    <Button type="primary" htmlType="submit">
                        {t('common.save')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}