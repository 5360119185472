import React, { useState, useContext, createContext } from "react";
import axios from "axios";
import authHeader from "./auth-header";

const authContext = createContext();

// Provider component that wraps app and makes auth object 
// available to any child component that calls useAuth()
export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}

// Hook for child components to get the auth object
// and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, set_user] = useState(false);
  
  const login = (login, password) => {
    return axios
      .post(process.env.REACT_APP_SERVER_URL + "/api/v1/auth/signin", {
        login,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        set_user(response.data);
        return response.data;
      });
  };

  const logout = () => {
    localStorage.removeItem("user");
    set_user(false);
  };
  
  const auth_from_storage = async () => {
    let u = localStorage.getItem("user");
    if (u) {
      return await axios({
        url: process.env.REACT_APP_SERVER_URL + "/api/v1/auth/checklogged",
        method: 'post',
        headers: authHeader(),
      })
      .then((response) => {
        set_user(u);
        return true;        
      })
      .catch(err => {
        set_user(false);
        localStorage.removeItem('user');
        return false;
      });
    }
    return false;
  }

  return {
    user,
    login,
    logout,
    auth_from_storage
  }
}

