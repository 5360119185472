import React, {useEffect, useImperativeHandle, useState} from "react";
import {Button, DatePicker, Modal, Select, Table, Tooltip} from "antd";
import {
    CalendarOutlined,
    DeleteOutlined,
    NodeIndexOutlined,
    FileTextOutlined,
    CoffeeOutlined,
    FilePdfOutlined,
    FileSearchOutlined,
    LoadingOutlined,
    CloseCircleOutlined
} from "@ant-design/icons";
import moment from "moment";
import {useTranslation} from "react-i18next";
// INTERNAL
import races from "../services/race.service";
import {useHistory} from "react-router-dom";
import Loader from "../components/loader"
import settings from "../services/settings.service";

const {confirm} = Modal;
const {Option} = Select;

export const RaceCalendarDataList = React.forwardRef((props, ref) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [raceList, setRaceList] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment());
    const [selectedVenue, setSelectedVenue] = useState();
    const [user, setUser] = useState({});
    const [mecActivated, setMecActivated] = useState(false);
    const dateFormat = "YYYY-MM-DD";
    const history = useHistory();

    const competitors = [
        {
            title: t("RaceCalendar.Competitor.Number"),
            key: "Number",
            align: "right",
            sorter: (a, b) => a.number - b.number,
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Number"}>{record.number}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Name"),
            key: "Name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Name"}>{record.name}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Status"),
            key: "Status",
            sorter: (a, b) => a.status.localeCompare(b.status),
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Status"}>{t(`competitor.status.${record.status}`)}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Distance"),
            key: "Distance",
            align: "right",
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Distance"}>{record.distance}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Driver"),
            key: "Driver",
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Driver"}>{record.driver}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Trainer"),
            key: "Trainer",
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Trainer"}>{record.trainer}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Owner"),
            key: "Owner",
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Owner"}>{record.owner}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Draw"),
            key: "Draw",
            align: "right",
            render: (values, record) => {
                return (
                    record.draw !== 0 ?
                    <>
                        <p key={record.uuid + "_Draw"}>{record.draw}</p>
                    </>:''
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.Rank"),
            key: "Rank",
            align: "right",
            sorter: (a, b) => a.rank - b.rank,
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Rank"}>{record.rank}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.OfficialRank"),
            key: "OfficialRank",
            align: "right",
            sorter: (a, b) => a.officialRank - b.officialRank,
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_OfficialRank"}>{record.officialRank}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.ArrivalTime"),
            key: "ArrivalTime",
            sorter: (a, b) => a.arrivalTime - b.arrivalTime,
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_ArrivalTime"}>{record.arrivalTime}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitor.OfficialArrivalTime"),
            key: "OfficialArrivalTime",
            sorter: (a, b) => a.officialArrivalTime - b.officialArrivalTime,
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_OfficialArrivalTime"}>{record.officialArrivalTime}</p>
                    </>
                );
            },
        },
    ]

    // columns definition ---------------------------------------------
    const columns = [
        {
            title: t("RaceCalendar.Date"),
            key: "Date",
            render: (values, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Date"}>{new Date(record.date).toLocaleDateString()}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Venue"),
            key: "Venue",
            render: (value, record) => {
                return (
                    <>
                        <Tooltip title={t("monitoring.meetingShortcut")+`${record.meeting}`}>
                            <p key={record.uuid + "_Venue"}>{record.venueLabel}</p>
                        </Tooltip>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Number"),
            key: "Number",
            align: "right",
            sorter: (a, b) => a.number - b.number,
            render: (value, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Number"}>{record.number}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Start_Time"),
            key: "Start_Time",
            render: (value, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Start_Time"}>{new Date(record.date).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit'
                        })}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Label"),
            key: "Label",
            render: (value, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Label"}>{record.label}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Code"),
            key: "Code",
            render: (value, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Code"}>{record.trackCode}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Competitors_Number"),
            key: "Competitors",
            align: "right",
            render: (value, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Competitors_Number"}>{record.competitors.length}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Distance"),
            key: "Distance",
            align: "right",
            render: (value, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Distance"}>{record.distance}</p>
                    </>
                );
            },
        },
        {
            title: t("RaceCalendar.Type"),
            key: "Type",
            render: (value, record) => {
                return (
                    <>
                        <p key={record.uuid + "_Race_Type"}>{record.type}</p>
                    </>
                );
            },
        },
        {
            title: t("common.col_action"),
            dataIndex: "action",
            key: "action",
            className: "col_action",
            render: (value, race) => {
                return (
                    <>
                        <Tooltip title={t("common.del")}>
                            <Button
                                onClick={(e) => del(e, race.uuid)}
                                className="ant-btn-circle"
                                icon={<DeleteOutlined/>}
                            />
                        </Tooltip>
                        {user.roles && user.roles.includes("ROLE_ADMIN") && <>
                        <Tooltip title={t("RaceCalendar.getData")}>
                            <Button
                                onClick={(e, ) => getMecRaceData(e, race.uuid, false)}
                                className="ant-btn-circle"
                                disabled={race.mecStatus !== 'DONE'}
                                icon={race.mecStatus === 'FAILED' ? <CloseCircleOutlined /> :<FileTextOutlined/>}
                            />
                        </Tooltip>
                        <Tooltip title={t("RaceCalendar.getSectionalData")}>
                            <Button
                                onClick={(e) => getMecRaceData(e, race.uuid, true)}
                                className="ant-btn-circle"
                                disabled={race.mecStatus !== 'DONE'}
                                icon={race.mecStatus === 'FAILED' ? <CloseCircleOutlined /> :<FileSearchOutlined/>}
                            />
                        </Tooltip>
                        <Tooltip title={t("RaceCalendar.launchMec")}>
                            <Button
                                onClick={(e) => launchMec(e, race.uuid)}
                                className="ant-btn-circle"
                                disabled={race.mecStatus === 'IN_PROGRESS'}
                                icon={selectMecButtonState(race)}
                            />
                        </Tooltip>
                        <Tooltip title={t("RaceCalendar.launchReport")}>
                            <Button
                                onClick={(e) => launchReport(e, race.uuid)}
                                className="ant-btn-circle"
                                disabled={race.reportStatus === 'IN_PROGRESS' || race.mecStatus === 'IN_PROGRESS'}
                                icon={selectReportButtonState(race)}
                            />
                        </Tooltip>
                        </>
                        }
                    </>
                );
            },
        },
        Table.EXPAND_COLUMN
    ];

    const selectMecButtonState = (race) => {
        if (race.mecStatus === 'IN_PROGRESS') {
            return <LoadingOutlined/>
        } else {
            return <CoffeeOutlined/>
        }
    }

    const selectReportButtonState = (race) => {
        if (race.reportStatus === 'IN_PROGRESS') {
            return <LoadingOutlined/>
        } else if (mecActivated && race.mecStatus !== 'DONE') {
            return null;
        } else {
            return <FilePdfOutlined/>
        }
    }

    // load_data ------------------------------------------------------

    const loadListFromServer = () => {
        setLoading(true);
        if (selectedDate) {
            races.list({
                date: (selectedDate) ? selectedDate.format("YYYY-MM-DD") : undefined,
                venueCode: (selectedVenue) ? selectedVenue : undefined
            }).then((r) => {
                if (r && r.data) {
                    setSiteList(r.data.venues);
                    setRaceList(r.data.races);
                    setSelectedVenue(selectedVenue);
                    setLoading(false);
                }
            })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setSiteList([]);
            setRaceList([]);
            setSelectedVenue(null);
        }
    };

    // handlers -------------------------------------------------------

    function datepickerHandler(date) {
        setSelectedDate(date);
        setSelectedVenue(null);
        setSiteList([]);
    }

    const venueHandler = (venue) => {
        setSelectedVenue(venue);
    }

    // methods --------------------------------------------------------

    // ADD
    const add = () => {
        history.push("/race/");
    };

    const getMecRaceData = async (e, uuid, isSectional) => {
        e.preventDefault();
        e.stopPropagation();
        await races.getMecRaceData(uuid, isSectional);
    }

    const launchMec = async (e, uuid) => {
        e.preventDefault();
        e.stopPropagation();
        await races.launchMec(uuid);
    }

    const launchReport = async (e, uuid) => {
        e.preventDefault();
        e.stopPropagation();
        await races.launchReport(uuid);
    }

    // DEL
    const del = async (e, uuid) => {
        e.preventDefault();
        e.stopPropagation();
        confirm({
            title: t("RaceCalendar.DeleteConfirmTitle"),
            content: t("RaceCalendar.DeleteConfirmContent"),
            onOk() {
                return new Promise(async (resolve) => {
                    await races.del(uuid);
                    await loadListFromServer();
                    resolve();
                });
            },
            onCancel() {
            },
        });
    };

    // useImperativeHandle --------------------------------------------
    useImperativeHandle(ref, () => ({

        add_race_ext() {
            add();
        }

    }))

    // useEffect ------------------------------------------------------

    // on date selection, load data from db
    useEffect(() => {
        loadListFromServer();
        setUser(JSON.parse(localStorage.getItem('user')));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, selectedVenue]);

    useEffect(() => {
        settings.getMecActivatedSetting().then((response) => {
            setMecActivated(response.data.value)
        })
    }, []);
    
    // render ---------------------------------------------------------
    return (
        <>
            <div className="psc_wrapper">
                <div className="psc_row">
                    <CalendarOutlined className="psc_icon"/>
                    <DatePicker
                        className="psc_input"
                        defaultValue={selectedDate}
                        value={selectedDate}
                        onChange={datepickerHandler}
                        format={dateFormat}
                        placeholder={t('RaceCalendar.Select_Date')}
                    />
                </div>
                <div className="psc_row">
                    <NodeIndexOutlined className="psc_icon"/>
                    <Select
                        className="psc_input select"
                        mode="Tags"
                        id="SiteH"
                        placeholder={t('RaceCalendar.Select_Site')}
                        style={{width: 500}}
                        onChange={venueHandler}
                        defaultValue={selectedVenue}
                        value={selectedVenue}
                        allowClear
                        showSearch>
                        {
                            siteList.map((venue) => {
                                return (
                                    <Option
                                        key={venue.code}
                                        value={venue ? venue.code : ""}>
                                        <span
                                            className="race_number">{`${t('monitoring.meetingShortcut')}${venue.meeting}`}</span>
                                        <span>{venue.label}</span>
                                    </Option>
                                );
                            })}
                    </Select>
                </div>
            </div>
            <div className="pdl_wrapper">
                {loading ? <Loader/> : <div className="data_list programme">
                    <Table
                        bordered
                        columns={columns}
                        dataSource={raceList}
                        loading={loading}
                        rowKey="uuid"
                        className="common_list race_list"
                        rowClassName={(record) => {
                            if (record.unknownTrack) return "unknown_track";
                        }}
                        pagination={{pageSize: 20}}
                        expandable={{
                            expandedRowRender: record =>
                                <Table
                                    dataSource={record.competitors}
                                    columns={competitors}
                                    rowKey="uuid"
                                    className="competitor_list"
                                    pagination={false}
                                />
                        }}
                    />
                </div>}
            </div>
        </>
    );
});

export default RaceCalendarDataList;
